var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        value: _vm.value,
        "allow-clear": "",
        "show-search": "",
        "filter-option": false,
        disabled: _vm.disabled,
        dropdownMatchSelectWidth: false,
        loading: _vm.loading
      },
      on: { search: _vm.onSearch, change: _vm.onChange }
    },
    _vm._l(_vm.optInvoices, function(item) {
      return _c(
        "a-select-option",
        { key: item.key, attrs: { value: item.value } },
        [
          _c("a-tooltip", [
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(_vm._s(item.label))
            ]),
            _vm._v(" " + _vm._s(item.label) + " ")
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }