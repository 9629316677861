/**
 * @deprecated
 * use {@linkcode InvoiceArPrepaymentStatusEnum}
 */
export enum PREPAYMENT_STATUS {
  APPLIED = "Applied",
  CANCELLED = "Cancelled",
  PAID = "Paid",
  UN_APPLIED = "Un-Applied",
  PARTIAL_PAID = "Partial Paid",
  UNPAID = "Unpaid",
}
