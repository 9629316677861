

























import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { useInvoiceAP } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class APPaymentsSelectInvoiceAP extends Vue {
  @Prop({ default: "" }) value!: string;
  @Prop({ default: "" }) currencyCode!: string;
  @Prop({ default: "" }) branchId!: string;
  @Prop({ default: "" }) supplierId!: string;
  @Prop({ default: false }) disabled!: boolean;

  optInvoices: Option[] = [];
  loading = false;

  created(): void {
    const q = this.buildQuery();
    const params = new RequestQueryParams(q);
    if (this.value) {
      const searchBySecureId = new SearchBuilder()
        .push(["secureId", this.value])
        .build();
      params.search = searchBySecureId;
    }
    this.fetchInvoicesAP(params);
  }

  @Watch("currencyCode")
  onChangeCurrencyCode(): void {
    const params = new RequestQueryParams(this.buildQuery());
    this.fetchInvoicesAP(params);
  }

  @Watch("branchId")
  onChangeBranch(): void {
    const params = new RequestQueryParams(this.buildQuery());
    this.fetchInvoicesAP(params);
  }

  @Watch("supplierId")
  onChangeSupplier(): void {
    const params = new RequestQueryParams(this.buildQuery());
    this.fetchInvoicesAP(params);
  }

  buildQuery(): string {
    const builder = new SearchBuilder();
    return builder
      .push(["status", INVOICE_AP_STATUS.PARTIAL_PAID])
      .or()
      .push(["status", INVOICE_AP_STATUS.UNPAID])
      .and()
      .push(["invoiceType", "Invoice AP"])
      .and()
      .push(["branchWarehouse.secureId", this.branchId])
      .and()
      .push(["supplier.secureId", this.supplierId])
      .and()
      .push(["priceCurrency.currencyCode", this.currencyCode])
      .build();
  }

  onChange(value = ""): void {
    this.$emit("change", value);
  }

  onSearch(value = ""): void {
    const builder = new SearchBuilder();
    const q: string[] = [this.buildQuery()];
    if (value) {
      q.push(builder.push(["documentNumber", value], { like: "both" }).build());
    }
    const params = new RequestQueryParams(q.join(builder.AND));
    debounce(() => {
      this.fetchInvoicesAP(params);
    });
  }

  fetchInvoicesAP(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useInvoiceAP();
    this.loading = true;
    findAll(params)
      .then(response => {
        this.optInvoices = toOptions(response.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
